import type { ReactNode } from 'react';
import { Broadcast } from './Broadcast';
import { Header } from './Header';
import { Footer } from './Footer';

type MainProps = {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    podlets?: Record<string, any> | undefined;
    broadcastUrl?: string | undefined;
    className?: string | undefined;
    troika?: boolean; // remove when Troika is gone
    children: ReactNode;
};

export const Main = ({ podlets, broadcastUrl, troika, className, children }: MainProps) => {
    const renderMain = () => (
        <main className={`${troika ? 'pageholder' : 'page-container'} ${className ?? ''}`}>
            <>
                {broadcastUrl && <Broadcast url={broadcastUrl} troika={troika} />}
                {children}
            </>
        </main>
    );

    return podlets ? (
        <>
            {/* TODO all the podlets get a diff between server and client rendered content sometimes, but why? */}
            {podlets.header ? <div suppressHydrationWarning={true} {...podlets.header.props} /> : <Header />}
            <div suppressHydrationWarning={true} {...podlets.advertising?.props} />
            {renderMain()}
            {podlets.footer ? <div suppressHydrationWarning={true} {...podlets.footer.props} /> : <Footer />}
            {podlets.js?.map((script) => (
                <script {...script.props} key={script.key} crossOrigin="" defer />
            ))}
        </>
    ) : (
        renderMain()
    );
};

export const MainStatic = ({ troika, className, children }: Omit<MainProps, 'podlets' | 'broadcastUrl'>) => (
    <>
        <Header />
        <main data-testid="Main-test-id" className={`${troika ? 'pageholder' : 'page-container'} ${className ?? ''}`}>
            {children}
        </main>
        <Footer />
    </>
);

// Two 'page-container' areas with different background colors, common for admin pages. Fabric only.
export const MainSplit = ({ top, podlets, broadcastUrl, className, children }: Omit<MainProps, 'troika'> & { top: ReactNode }) => {
    const renderMain = () => (
        <>
            <section className={`page-container ${className ?? ''}`}>
                <>
                    {broadcastUrl && <Broadcast url={broadcastUrl} />}
                    {top}
                </>
            </section>
            <main className="bg-bluegray-50">
                <div className={`page-container bg-bluegray-50 ${className ?? ''}`}>{children}</div>
            </main>
        </>
    );

    return podlets ? (
        <>
            {podlets.header ? <div {...podlets.header.props} /> : <Header />}
            <div {...podlets.advertising?.props} />
            {renderMain()}
            {podlets.footer ? <div {...podlets.footer.props} /> : <Footer />}
            {podlets.js?.map((script) => (
                <script {...script.props} key={script.key} crossOrigin="" defer />
            ))}
        </>
    ) : (
        renderMain()
    );
};
